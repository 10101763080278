import React, { Suspense } from 'react'

import SubscribeEntry from './SubscribeEntry'
const SignUp = React.lazy(() =>
  import(
    /* webpackChunkName: 'subscribe-signup' */ 'components_v2/SubscribePartner/Signup'
  )
)

export const SignupTempEntry = ({ mweOfferSlug, ...rest }) => {
  return (
    <SubscribeEntry user={null}>
      <Suspense fallback=''>
        <SignUp mweOfferSlug={mweOfferSlug} {...rest} />
      </Suspense>
    </SubscribeEntry>
  )
}

export default SignupTempEntry
