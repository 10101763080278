import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'

const Page = React.lazy(() =>
  import(
    /* webpackChunkName: 'support-main-page' */ 'components_v2/SupportPages/Main'
  )
)

export const SupportPageTempEntry = ({ categoryList }) => {
  return (
    <TempEntry
      loadUser
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <Page categoryList={categoryList} />
      </Suspense>
    </TempEntry>
  )
}

SupportPageTempEntry.propTypes = {
  category: PropTypes.string,
  articleTitle: PropTypes.string
}

export default SupportPageTempEntry
