import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'
const LandingPage = React.lazy(() => import('components_v2/EmailUs/Page'))

/****************************************
/* TODO: React Refactor
/*  This is the temporary Entry Point for the Email Us page.
/*  Once we switch over to full React, we can do away with this page.
*/

export const LandingPageTempEntry = ({ user, success, domain }) => {
  return (
    <TempEntry
      loadUser
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <LandingPage success={success} domain={domain} />
      </Suspense>
    </TempEntry>
  )
}

LandingPageTempEntry.propTypes = {
  user: PropTypes.object,
  success: PropTypes.bool,
  domain: PropTypes.string
}

export default LandingPageTempEntry
