import React, { Fragment, Suspense, useState, useEffect } from 'react'

import { axios } from 'api'

import TempEntry from '../TempEntry'
import { defaultPromoProps } from 'components_v2/Home/SignedOut/_media'
const SignedOutHome = React.lazy(() =>
  import(/* webpackChunkName: 'home-so' */ 'components_v2/Home/SignedOut')
)

/****************************************
/* TODO: React Refactor
/*  This is the temporary Entry Point for both signed-in and signed-out Home.
/*  Once we switch over to full React, we can do away with this page.
*/

export const SOHomeTempEntry = () => {
  const [promoData, setPromoData] = useState()

  useEffect(() => {
    const getActivePromo = async () => {
      try {
        const res = await axios.get(
          '/promotions/current?page=signed_out_home'
        )

        // If heroHeader.title is present, we assume all values are present
        if (res.data && res.data.heroHeader && res.data.heroHeader.title) {
          setPromoData(res.data)
        } else {
          // There is no response body (i.e. no active promo), so set our default props.
          setPromoData(defaultPromoProps)
        }
      } catch {
        // Our get request errored out for whatever reason, hydrate the page with our default props anyway.
        setPromoData(defaultPromoProps)
      }
    }
    getActivePromo()
  }, [])

  return (
    <Fragment>
      {promoData && (
        <TempEntry
          mt='0px'
          controls={{
            footerFlush: {
              mobile: true,
              desktop: true
            }
          }}
        >
          <Suspense fallback=''>
            <SignedOutHome {...promoData} />
          </Suspense>
        </TempEntry>
      )}
    </Fragment>
  )
}

export default SOHomeTempEntry
