import TempEntry from 'components_v2/_entryPoints/TempEntry'
import React, { Suspense } from 'react'

const SignIn = React.lazy(() => import('components_v2/SignIn/SignIn'))

export const SignInTempEntry = ({ ...rest }) => {
  return (
    <TempEntry
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <SignIn {...rest} />
      </Suspense>
    </TempEntry>
  )
}

export default SignInTempEntry
