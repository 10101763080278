import React, { Suspense } from 'react'

import TempEntry from '../../TempEntry'
const LiveClassSchedule = React.lazy(() =>
  import(
    /* webpackChunkName: 'live-class-schedule' */ 'components_v2/Live/ClassSchedule'
  )
)

const LiveClassScheduleTempEntry = (props) => {
  return (
    <TempEntry loadUser mt='0px' controls={{ footer: false }}>
      <Suspense fallback={<></>}>
        <LiveClassSchedule {...props} />
      </Suspense>
    </TempEntry>
  )
}

export default LiveClassScheduleTempEntry
