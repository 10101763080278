import React, { Suspense } from 'react'
import TempEntry from '../../TempEntry'

const SignInOffer = React.lazy(() =>
  import(
    /* webpackChunkName: 'subscribe-signup' */ 'components_v2/Mastercard/signin-offer/SignInOffer'
  )
)

export const SignInMWETempEntry = ({
  user,
  mweOfferAvailed,
  mweCurrentFrequency,
  mweNotEligible,
  stripeAPIKey,
  mweOfferSlug,
  consolationOfferSlug
}) => {
  return (
    <TempEntry
      user={user}
      deprecated
      mt={['0px', null, '0px']}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <SignInOffer
          stripeAPIKey={stripeAPIKey}
          mweCurrentFrequency={mweCurrentFrequency}
          mweOfferAvailed={mweOfferAvailed}
          mweNotEligible={mweNotEligible}
          mweOfferSlug={mweOfferSlug}
          consolationOfferSlug={consolationOfferSlug}
        />
      </Suspense>
    </TempEntry>
  )
}

export default SignInMWETempEntry
