import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import SubscribeEntry from './SubscribeEntry'
const Reactivate = React.lazy(() =>
  import(
    /* webpackChunkName: 'subscribe-reactivate' */ 'components_v2/Subscribe/Reactivate'
  )
)

export const ReactivateTempEntry = ({ user, ...rest }) => {
  return (
    <SubscribeEntry user={user}>
      <Suspense fallback=''>
        <Reactivate {...rest} />
      </Suspense>
    </SubscribeEntry>
  )
}

ReactivateTempEntry.propTypes = {
  user: PropTypes.object
}

export default ReactivateTempEntry
