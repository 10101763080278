import React, { useContext, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

import { axios } from 'api'
import { useUserContext } from 'context'
import { utmParams } from 'helpers'

import reducer from './reducer'
import { initPage, unloadForm, validCheck } from './formatter'

const Store = React.createContext(null)
export const useSurveyContext = () => useContext(Store)

//
// step: Current step number
//    -> this could be renamed to: stepNum
// pages: In order to have smooth transitions, we keep the current step/page in state while loading the next page
//    -> this could be renamed to: steps
// pages[step]: Contains data (page, formData, etc.) for a given step
//    -> this could be accessed via: getStep()
//
const initialState = {
  formDataUpdated: false,
  loading: false,
  complete: false,
  error: null,
  guid: null,
  pages: {},
  step: 0
}

export const SurveyStoreProvider = ({ type, children }) => {
  const { user } = useUserContext()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [, setCookie] = useCookies([`${type}_survey_guid`])
  const heroImageParam = 'hero_image_sizes=large'

  const scrollTo = (location) => {
    // location: top, bottom
    window.scroll({
      top: location === 'top' ? 0 : document.body.scrollHeight,
      behavior: 'smooth'
    })
  }

  // Exported action
  const error = (message) => {
    dispatch({ type: 'setError', payload: message })

    if (message) {
      // Wait for alert to be added to the DOM to get accurate scrollHeight (see Layout/index.js)
      setTimeout(() => {
        scrollTo('bottom')
      }, 100)
    }
  }

  const handleError = (errorObject) => {
    if (errorObject.response) {
      error(errorObject.response.message)
    } else {
      // This is most likely due to a network error (no response object returned, thus no response.response); unless we want to refactor the error our axios.config throws.
      error(
        'We apologize, but there has been an error. Please try again or contact support@alomoves.com.'
      )
    }
  }

  useEffect(() => {
    const step = state.pages[`step_${state.step}`]

    // We first check to see if the formDataUpdated bool is true (set during the 'answer' action).
    // If so, we make sure that the pages object has entries and that the step we're looking at is not skippable
    // (If it were, then this check would mess up the skippable === valid logic)
    if (
      !state.formDataUpdated ||
      (Object.keys(state.pages).length === 0 &&
        state.pages.constructor === Object)
    ) {
      return
    } else if (step.skippable) {
      dispatch({ type: 'setFormDataUpdated', payload: false })
      return
    }

    const valid = validCheck({
      components: step.page.layoutContent.components,
      formData: step.formData
    })

    dispatch({ type: 'setFormDataUpdated', payload: false })
    dispatch({ type: 'setValid', payload: valid })
  }, [state.formDataUpdated])

  // Callback for when survey is complete (last page submitted)
  useEffect(() => {
    if (state.complete) {
      if (!user) {
        // Survey is not linked to user yet -> set cookie for linking later
        setCookie(`${type}_survey_guid`, state.guid, { path: '/' })
      }

      switch (type) {
        case 'onboarding':
          // Backend will redirect if user already has an active membership
          window.location.href = '/subscribe'
          break
      }
    }
  }, [user, state.complete])

  //
  // Actions (API)
  //
  const start = async () => {
    if (state.loading) return
    dispatch({ type: 'setLoading', payload: true })

    // Referrer
    let referrer = document.referrer
    if (!referrer) referrer = null // Server does not like empty strings
    try {
      const response = await axios.post(`/surveys/start?${heroImageParam}`, {
        version: 'latest',
        type: type,
        include_first_page: true,
        referrer_url: referrer,
        utm: utmParams()
      })

      dispatch({ type: 'setGuid', payload: response.data.surveyGuid })

      // Format page payload
      const page = initPage({ page: response.data.firstPage, step: 1 })
      dispatch({ type: 'setPage', payload: page })

      scrollTo('top')
    } catch (error) {
      handleError(error)
    }
  }

  const submit = async ({ step }) => {
    if (state.loading) return
    dispatch({ type: 'setLoading', payload: true })

    const current = state.pages[`step_${step}`]
    const newState = unloadForm(current)
    const isLastPage = current.page.last

    try {
      const response = await axios.post(
        `/surveys/${state.guid}/pages/${step}?${heroImageParam}`,
        {
          new_state: newState,
          include_next_page: !isLastPage
        }
      )

      if (isLastPage) {
        dispatch({ type: 'complete' })
      } else {
        // Format new page payload
        const page = initPage({ page: response.data.nextPage, step: step + 1 })
        dispatch({ type: 'setPage', payload: page })

        scrollTo('top')
      }
    } catch (error) {
      handleError(error)
    }
  }

  const back = async (step) => {
    if (state.loading || step <= 1) return
    dispatch({ type: 'setLoading', payload: true })

    try {
      const response = await axios.get(
        `/surveys/${state.guid}/pages/${step - 1}?${heroImageParam}`
      )

      // Format previous page payload
      const page = initPage({ page: response.data, step: step - 1 })
      dispatch({ type: 'setPage', payload: page })

      scrollTo('top')
    } catch (error) {
      handleError(error)
    }
  }

  const removeStep = (step) => {
    dispatch({ type: 'removeStep', payload: step })
  }

  const actions = {
    start,
    submit,
    back,
    error,
    removeStep
  }

  //////////////////////////////////////////////////

  return (
    <Store.Provider value={{ state, dispatch, actions, transitionSpeed: 350 }}>
      {children}
    </Store.Provider>
  )
}

SurveyStoreProvider.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
