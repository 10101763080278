import React, { Suspense } from 'react'

import TempEntry from '../../TempEntry'
const Community = React.lazy(() =>
  import(/* webpackChunkName: 'community' */ 'components_v2/Community')
)

/****************************************
 * TODO: React Refactor
 *  This is the temporary Entry Point for Community.
 *  Once we switch over to full React, we can do away with this page.
 *
 *  This entrypoint uses the legacy withRedux due to some deep integration going on in the video component.
 */

export const CommunityTempEntry = () => {
  return (
    <TempEntry
      deprecated
      loadUser
      mt={['0px', null, '0px']}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback={<></>}>
        <Community />
      </Suspense>
    </TempEntry>
  )
}

export default CommunityTempEntry
