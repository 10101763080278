import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'
const Search = React.lazy(() =>
  import(/* webpackChunkName: 'search' */ 'components_v2/Search')
)

/****************************************
 * TODO: React Refactor
 *  This is the temporary Entry Point for Search.
 *  Once we switch over to full React, we can do away with this page.
 */

export const SearchTempEntry = ({ query }) => {
  return (
    <TempEntry
      deprecated
      loadUser
      mt={['0px', null, '0px']}
      padding={['0px', null, '0px']}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback={<></>}>
        <Search query={query} />
      </Suspense>
    </TempEntry>
  )
}

SearchTempEntry.propTypes = {
  query: PropTypes.string
}

export default SearchTempEntry
