import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import { EmbedProvider } from 'context'

import SubscribeEntry from './SubscribeEntry'
const Welcome = React.lazy(() =>
  import(
    /* webpackChunkName: 'subscribe-welcome' */ 'components_v2/Subscribe/Welcome'
  )
)

export const WelcomeTempEntry = ({ user, ...rest }) => {
  return (
    <EmbedProvider>
      <SubscribeEntry
        user={user}
        controls={{
          alert: false,
          footerFlush: {
            mobile: true,
            desktop: true
          }
        }}
      >
        <Suspense fallback=''>
          <Welcome {...rest} />
        </Suspense>
      </SubscribeEntry>
    </EmbedProvider>
  )
}

WelcomeTempEntry.propTypes = {
  user: PropTypes.object
}

export default WelcomeTempEntry
