import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'
// import MastercardCheckout from '../../Mastercard/MastercardCheckout'
const MastercardCheckout = React.lazy(() =>
  import(
    /* webpackChunkName: 'plan-entry-proxy' */ 'components_v2/Mastercard/MastercardCheckout'
  )
)

export const MastercardTempEntry = ({ user, stripePublishableKey }) => {
  return (
    <TempEntry
      controls={{
        navbarLinks: false, // Links, hoverables, search, etc.
        navbarAuth: false,
        footer: false,
        footerLinks: false
      }}
      deprecated
      mt={[null, null, null]}
      user={user}
    >
      <Suspense fallback={<></>}>
        <MastercardCheckout stripePublishableKey={stripePublishableKey} />
      </Suspense>
    </TempEntry>
  )
}

MastercardTempEntry.propTypes = {}

export default MastercardTempEntry
