import TempEntry from 'components_v2/_entryPoints/TempEntry'
import React, { Suspense } from 'react'

const ForgotPassword = React.lazy(() =>
  import('components_v2/SignIn/ForgotPassword/ForgotPassword')
)

export const ForgotPasswordTempEntry = ({ email, ...rest }) => {
  return (
    <TempEntry
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <ForgotPassword {...rest} />
      </Suspense>
    </TempEntry>
  )
}

export default ForgotPasswordTempEntry
