import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'
const Bend = React.lazy(() =>
  import(/* webpackChunkName: 'Bend-Components' */ 'components_v2/Bend')
)

//
// Dev page for displaying and testing all ui/bend components
//
export const BendTempEntry = ({ user, ...rest }) => (
  <TempEntry user={user}>
    <Suspense fallback=''>
      <Bend {...rest} />
    </Suspense>
  </TempEntry>
)

BendTempEntry.propTypes = {
  user: PropTypes.object
}

export default BendTempEntry
