export default [
  {
    title: '20 Questions for Your Emotional Wellness',
    body: 'Alo Moves instructors share their favorite prompts for self-reflection.',
    alt: 'Jackie Stewart meditating on a green couch',
    link: 'https://blog.alomoves.com/mindfulness/check-in-with-yourself-20-questions-for-your-emotional-wellness',
    images: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/stories/emotional_wellness.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/stories/emotional_wellness.png'
    }
  },
  {
    title: ' The Best Pilates Exercises for Your Arms',
    body: 'Use weights to light up your arms from your shoulders to your wrists.',
    alt: 'Bianca Melas outside in a wide Sumo Squat',
    link: 'https://blog.alomoves.com/movement/the-best-pilates-exercises-to-tone-your-arms',
    images: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/stories/pilates.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/stories/pilates.png'
    }
  },
  {
    title: '8 Yin Yoga Poses to Relieve Low Back Pain',
    body: 'Improve your flexibility and mobility in this relaxing yoga sequence.',
    alt: 'Annie Moves in a seated twist outside ',
    link: 'https://blog.alomoves.com/editors-picks/8-yin-yoga-poses-to-relieve-low-back-pain-3zzpp',
    images: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/stories/yoga.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/stories/yoga.png'
    }
  }
]
