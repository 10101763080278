import React, { Suspense } from 'react'

import { Loading } from 'ui/bend'
import { useFetchAPI } from 'hooks'

import TempEntry from '../TempEntry'
const Redemption = React.lazy(() =>
  import(/* webpackChunkName: 'redeem' */ 'components_v2/Redemption')
)

export const RedemptionTempEntry = (props) => {
  const slug = window.location.pathname.slice(1)
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const user = props?.user

  const [{ data, loading, error }] = useFetchAPI(
    `/redemption_offers/${slug}/static`,
    {},
    {
      params: {
        v: '1'
      }
    }
  )

  return (
    <TempEntry
      mt='0px'
      controls={{ footer: false }}
      loadUser
      {...(user && { user })}
    >
      <Suspense fallback=''>
        <Loading error={error} loading={loading}>
          {data && <Redemption offer={data} {...props} code={code} />}
        </Loading>
      </Suspense>
    </TempEntry>
  )
}

export default RedemptionTempEntry
