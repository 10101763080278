import React, { Suspense } from 'react'

import TempEntry from '../../TempEntry'
const NotFound = React.lazy(() => import('components_v2/NotFound/NotFoundPage'))

/****************************************
/* TODO: React Refactor
/*  Once we switch over to full React, we can do away with this page.
*/

export const NotFoundTempEntry = () => {
  return (
    <TempEntry
      loadUser
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <NotFound />
      </Suspense>
    </TempEntry>
  )
}

export default NotFoundTempEntry
