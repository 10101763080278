import React, { Suspense } from 'react'

import { GridItem, Loading, MainContainer, P } from 'ui/bend'
import { useFetchAPI } from 'hooks'

import TempEntry from '../../TempEntry'
const GiftShow = React.lazy(() =>
  import(/* webpackChunkName: 'gifting-success' */ 'components_v2/Gifting/Show')
)

// Grab uuid from url path.
const uuid = window.location.pathname.split('/').pop()

const ErrorComponent = () => (
  <MainContainer>
    <GridItem
      gridColumn='main-start / main-end'
      mx='auto'
      pt={['24px', '48px']}
    >
      <P>
        We apologize, but this page is unavailable. Please contact{' '}
        <a href='mailto:support@alomoves.com'>support@alomoves.com</a> for
        further assistance.
      </P>
    </GridItem>
  </MainContainer>
)

const GiftingShowTempEntry = () => {
  const [{ data, loading, error }] = useFetchAPI(`/gifting/${uuid}`)

  return (
    <TempEntry loadUser mt='0px' controls={{ footer: false }}>
      <Loading loading={loading} error={error} ErrorComponent={ErrorComponent}>
        <Suspense fallback={<></>}>
          <GiftShow data={data} uuid={uuid} />
        </Suspense>
      </Loading>
    </TempEntry>
  )
}

export default GiftingShowTempEntry
