import React, { Suspense } from 'react'

import TempEntry from '../../TempEntry'
const NotPromoFoundPage = React.lazy(() =>
  import('components_v2/NotFound/NotFoundPromoPage')
)

/****************************************
/* TODO: React Refactor
/*  Once we switch over to full React, we can do away with this page.
*/

export const NotPromoFoundTempEntry = () => {
  return (
    <TempEntry
      loadUser
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <NotPromoFoundPage />
      </Suspense>
    </TempEntry>
  )
}

export default NotPromoFoundTempEntry
