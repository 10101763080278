import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import { EmbedProvider, StripeCheckoutProvider } from 'context'

import SubscribeEntry from './SubscribeEntry'
const Membership = React.lazy(() =>
  import(
    /* webpackChunkName: 'subscribe-membership' */ 'components_v2/Subscribe/Membership'
  )
)

export const MembershipTempEntry = ({ user, stripeApiKey, ...rest }) => {
  return (
    <EmbedProvider>
      <SubscribeEntry user={user}>
        <StripeCheckoutProvider stripeApiKey={stripeApiKey}>
          <Suspense fallback=''>
            <Membership {...rest} />
          </Suspense>
        </StripeCheckoutProvider>
      </SubscribeEntry>
    </EmbedProvider>
  )
}

MembershipTempEntry.propTypes = {
  user: PropTypes.object,
  stripeApiKey: PropTypes.string
}

export default MembershipTempEntry
