import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import DeliveryFormProvider from 'components_v2/Gifting/New/Delivery/DeliveryFormProvider'
import { StripeCheckoutProvider } from 'context'
import { useFetchAPI } from 'hooks'
import { Loading } from 'ui/bend'

import TempEntry from '../../TempEntry'

const Gifting = React.lazy(() =>
  import(/* webpackChunkName: 'gifting' */ 'components_v2/Gifting/New')
)

export const GiftingEntry = ({ stripeApiKey }) => {
  const [{ data, loading, error }] = useFetchAPI('gifting/card_types')
  const [{ data: planData, loading: planLoading, error: planError }] =
    useFetchAPI('gifting/plans')

  return (
    <Loading loading={loading || planLoading} error={error || planError}>
      <StripeCheckoutProvider stripeApiKey={stripeApiKey}>
        <DeliveryFormProvider cards={data} plans={planData}>
          <TempEntry
            mt='0px'
            deprecated
            loadUser
            controls={{
              footerFlush: {
                desktop: true
              }
            }}
          >
            <Suspense fallback={<></>}>
              <Gifting cards={data} />
            </Suspense>
          </TempEntry>
        </DeliveryFormProvider>
      </StripeCheckoutProvider>
    </Loading>
  )
}
GiftingEntry.propTypes = {
  user: PropTypes.object
}

export default GiftingEntry
