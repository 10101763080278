export default [
  {
    title: 'Yoga',
    body: 'Get lost in your flow state with every yoga variety, from Vinyasa to Hatha.',
    alt: 'Annie Moves in a seated side stretch in front of a sunset',
    url: '/class-finder?styles=yoga&order=date',
    src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/categories/yoga.png'
  },
  {
    title: 'Fitness',
    body: 'Level up your workouts with Pilates, Barre, HIIT, Strength, and much more.',
    alt: 'Christa Janine holding weights in front of a pool.',
    url: '/class-finder?styles=fitness&order=date&',
    src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/categories/fitness.png'
  },
  {
    title: 'Mindfulness',
    body: 'Embrace your thoughts and emotions to put yourself in a better headspace.',
    alt: 'Susy Markoe Schieffelin playing singing bowls outdoors.',
    url: '/class-finder?styles=mindfulness&order=date&',
    src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/categories/mindfulness.png'
  },
  {
    title: 'Self-Care',
    body: 'Unwind with meditative rituals like dry brushing, gua sha, and journaling.',
    alt: 'Bianca Wise using a gua sha tool on her face.',
    url: '/playlists/3a522c2f-621f-4401-9f7a-ff489b35fe59',
    src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/categories/self-care.png'
  }
]
