import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../TempEntry'
import { SurveyStoreProvider } from 'components_v2/Survey/_store'
const Survey = React.lazy(() =>
  import(/* webpackChunkName: 'survey' */ 'components_v2/Survey')
)

export const SurveyTempEntry = ({ user, type, ...rest }) => (
  <TempEntry
    controls={{
      alert: false,
      footer: false,
      navbarLinks: false,
      navbarAuth: false
    }}
    deprecated
    mt='0px'
    user={user}
  >
    <SurveyStoreProvider type={type}>
      <Suspense fallback=''>
        <Survey type={type} {...rest} />
      </Suspense>
    </SurveyStoreProvider>
  </TempEntry>
)

SurveyTempEntry.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string.isRequired
}

export default SurveyTempEntry
