const defaultHeroBanner = {
  title: 'FREE THROUGH END OF YEAR',
  body: 'Sign up now to unlock free access to award-winning workouts, mindfulness & self-care on Alo Moves.',
  cta: 'Start Free Trial'
}

const defaultHeroHeader = {
  title: 'WELCOME TO YOUR WELLNESS ERA',
  body: 'Prioritize your wellness goals with award-winning fitness, meditation, nutrition, and self-care classes on Alo Moves.',
  cta: 'START FREE TRIAL'
}

const defaultTrialBanner = {
  title: 'Transform your life in 14 days',
  body: 'Get started today with free access to thousands of fitness and wellness classes.',
  cta: 'Start Your Free Trial'
}

const defaultHeroVideos = {
  desktop: [
    {
      src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/hero/desktop.webm',
      srcType: 'video/webm'
    },
    {
      src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/hero/desktop.mp4',
      srcType: 'video/mp4'
    }
  ],
  mobile: [
    {
      src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/hero/mobile.webm',
      srcType: 'video/webm'
    },
    {
      src: 'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/hero/mobile.mp4',
      srcType: 'video/mp4'
    }
  ],
  alt: 'Montage of people using Alo Moves at home and outside to work out, do yoga, and take self-care classes.'
}

export default {
  heroVideos: defaultHeroVideos,
  heroHeader: defaultHeroHeader,
  banner: defaultHeroBanner,
  trialBanner: defaultTrialBanner
}
