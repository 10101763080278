import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../stylesheets/application.css.scss'

const componentRequireContext = require.context(
  'components_v2/_entryPoints/grouped',
  true
)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
