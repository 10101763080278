import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'

const Show = React.lazy(() =>
  import(/* webpackChunkName: 'live-landing-page' */ 'components_v2/Live/Show')
)

/****************************************
/* TODO: React Refactor
/*  This is the temporary Entry Point for both signed-in and signed-out Home.
/*  Once we switch over to full React, we can do away with this page.
*/

export const ShowPageTempEntry = ({ user, id }) => {
  return (
    <TempEntry
      loadUser
      mt='0px'
      controls={{
        footer: false
      }}
    >
      <Suspense fallback=''>
        <Show id={id} />
      </Suspense>
    </TempEntry>
  )
}

ShowPageTempEntry.propTypes = {
  user: PropTypes.object
}

export default ShowPageTempEntry
