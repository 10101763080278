import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'

const Page = React.lazy(() =>
  import(
    /* webpackChunkName: 'support-article-page' */ 'components_v2/SupportPages/ArticleView/'
  )
)

export const ArticlePageTempEntry = ({
  category,
  title,
  slug,
  articleList,
  article
}) => {
  return (
    <TempEntry
      loadUser
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        {
          <Page
            category={category}
            title={title}
            slug={slug}
            articleList={articleList}
            article={article}
          />
        }
      </Suspense>
    </TempEntry>
  )
}

ArticlePageTempEntry.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string
}

export default ArticlePageTempEntry
