import TempEntry from 'components_v2/_entryPoints/TempEntry'
import React, { Suspense } from 'react'

const ChooseNewPassword = React.lazy(() =>
  import('components_v2/SignIn/ForgotPassword/ChooseNewPassword')
)

export const ChooseNewPasswordTempEntry = ({ user, ...rest }) => {
  return (
    <TempEntry
      mt='0px'
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <ChooseNewPassword user={user} {...rest} />
      </Suspense>
    </TempEntry>
  )
}

export default ChooseNewPasswordTempEntry
