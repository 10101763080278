import React, { Suspense } from 'react'

import TempEntry from '../../TempEntry'
const MastercardLandingPage = React.lazy(() =>
  import('components_v2/MastercardLandingPage/index')
)

export const MastercardLandingPageTempEntry = ({
  mweOfferSlug,
  user = null
}) => {
  return (
    <TempEntry
      deprecated
      mt={['0px', null, '0px']}
      pb={['30px']}
      user={user}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback=''>
        <MastercardLandingPage mweOfferSlug={mweOfferSlug} />
      </Suspense>
    </TempEntry>
  )
}

export default MastercardLandingPageTempEntry
