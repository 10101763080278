// These are the Featured Coaches on the main page.
// Using a Const until we get an API point set up with an Admin interface for changing them.

export default [
  {
    alt: '2023 Women’s Health Fitness Awards logo',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_WomensHealth.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_WomensHealth.png'
    },
    slug: 'women-health'
  },
  {
    alt: '2023 NBC Select Best of Wellness Awards logo',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_NBC.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_NBC.png'
    },
    slug: 'nbc'
  },
  {
    alt: 'Good Housekeeping Best Fitness Awards logo',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_GoodHousekeeping.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_GoodHousekeeping.png'
    },
    slug: 'good-housekeeping'
  },
  {
    alt: 'Sports Illustrated Swim Best of Awards logo',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_SISwim.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_SISwim.png'
    },
    slug: 'sport-illustrated'
  },
  {
    alt: '2023 Shape Best in Fitness logo',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_Shape.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/awards/Awards_Shape.png'
    },
    slug: 'shape'
  }
]
