const reducer = (state, action) => {
  switch (action.type) {
    case 'removeStep': {
      const newState = state
      delete newState.pages[`step_${action.payload}`]
      return {
        ...newState
      }
    }
    case 'setGuid': {
      return {
        ...state,
        guid: action.payload
      }
    }
    case 'setPage': {
      return {
        ...state,
        pages: {
          ...state.pages,
          ...action.payload.pages
        },
        step: action.payload.step,
        loading: false
      }
    }
    case 'setLoading': {
      return {
        ...state,
        loading: action.payload,
        error: null
      }
    }
    case 'setError': {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    case 'setAnswer': {
      const newState = state
      newState.formDataUpdated = true
      newState.pages[`step_${newState.step}`].formData = {
        ...newState.pages[`step_${newState.step}`].formData,
        ...action.payload
      }
      return {
        ...newState
      }
    }
    case 'setFormDataUpdated': {
      return {
        ...state,
        formDataUpdated: action.payload
      }
    }
    case 'setValid': {
      const newState = state
      newState.pages[`step_${newState.step}`] = {
        ...newState.pages[`step_${newState.step}`],
        valid: action.payload
      }
      return {
        ...newState
      }
    }
    case 'complete': {
      return {
        ...state,
        complete: true,
        loading: false,
        error: null
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
