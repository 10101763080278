import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'
const PrivacyPolicy = React.lazy(() =>
  import('components_v2/Privacy/PrivacyPage')
)

/****************************************
/* TODO: React Refactor
/*  This is the temporary Entry Point for both signed-in and signed-out Home.
/*  Once we switch over to full React, we can do away with this page.
*/

export const PrivacyTempEntry = ({ user }) => {
  return (
    <TempEntry
      user={user}
      loadUser
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
      mt='0px'
    >
      <Suspense fallback={<></>}>
        <PrivacyPolicy />
      </Suspense>
    </TempEntry>
  )
}

PrivacyTempEntry.propTypes = {
  user: PropTypes.object
}

export default PrivacyTempEntry
