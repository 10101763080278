import React from 'react'
import PropTypes from 'prop-types'

import { useEmbedContext } from 'context'

import TempEntry from '../../TempEntry'

const SubscribeEntry = ({ children, controls, user }) => {
  const embedded = useEmbedContext()
  const embedControls = {
    alert: false,
    navbar: false,
    footer: false
  }

  return (
    <TempEntry
      controls={embedded ? embedControls : controls}
      deprecated
      mt='0px'
      user={user}
    >
      {children}
    </TempEntry>
  )
}

SubscribeEntry.defaultProps = {
  controls: {
    navbarLinks: false,
    navbarAuth: true,
    footerLinks: false
  }
}

SubscribeEntry.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.object,
  user: PropTypes.object
}

export default SubscribeEntry
