import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../TempEntry'
const Plan = React.lazy(() =>
  import(/* webpackChunkName: 'plan-so' */ 'components_v2/Plan')
)

/****************************************
 * TODO: React Refactor
 *  This is the temporary Entry Point for Plan entries.
 *  Once we switch over to full React, we can do away with this page.
 *
 *  This entrypoint uses the legacy withRedux due to some deep integration going on in the video component.
 */

export const PlanTempEntry = ({ user, ...rest }) => {
  return (
    <TempEntry
      user={user}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
      deprecated
      mt={['16px', null, '64px']}
    >
      <Suspense fallback={<></>}>
        <Plan {...rest} />
      </Suspense>
    </TempEntry>
  )
}

PlanTempEntry.propTypes = {
  user: PropTypes.object
}

export default PlanTempEntry
