import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../TempEntry'
const SignedInHome = React.lazy(() =>
  import(/* webpackChunkName: 'home-si' */ 'components_v2/Home/SignedIn/Home')
)

/****************************************
/* TODO: React Refactor
/*  This is the temporary Entry Point for both signed-in and signed-out Home.
/*  Once we switch over to full React, we can do away with this page.
*/

export const SIHomeTempEntry = ({ user, mweOfferSlug }) => {
  return (
    <TempEntry deprecated user={user}>
      <Suspense fallback=''>
        <SignedInHome mweOfferSlug={mweOfferSlug} />
      </Suspense>
    </TempEntry>
  )
}

SIHomeTempEntry.propTypes = {
  user: PropTypes.object,
  mweOfferSlug: PropTypes.string
}

export default SIHomeTempEntry
