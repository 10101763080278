import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../TempEntry'
const PlanEntry = React.lazy(() =>
  import(/* webpackChunkName: 'plan-entry-proxy' */ 'components_v2/PlanEntry')
)

export const PlanEntryTempEntry = ({ user, ...rest }) => (
  <TempEntry user={user} mt='0px' deprecated>
    <Suspense fallback={<></>}>
      <PlanEntry {...rest} />
    </Suspense>
  </TempEntry>
)

PlanEntryTempEntry.propTypes = {
  user: PropTypes.object
}

export default PlanEntryTempEntry
