// Hydrate initial state for formData
export const initForm = (page) => {
  const formData = {}

  page.layoutContent.components.forEach((component) => {
    switch (component.type) {
      case 'boolean-1':
        formData[component.slug] = component.value || false
        break
      case 'multi-select-1':
      case 'multi-select-2':
      case 'multi-select-3':
      case 'multi-select-4':
      case 'single-select-1':
        formData[component.slug] = component.options
          .filter((opt) => opt.value === true)
          .map((opt) => opt.slug)
        break
    }
  })

  return formData
}

export const validCheck = ({ components, formData }) => {
  // Find invalid components by checking if minSelection requirement has not been met
  // Valid if there are no components with a minSelection requirement or minSelection requirement has been met
  return !components.find((component) => {
    const min = component.minSelections
    const value = formData[component.slug]

    return (
      min &&
      min > 0 &&
      // Multi-select: array (invalid if length < min)
      // Single-select: string (invalid if !value)
      ((Array.isArray(value) && value.length < min) || !value)
    )
  })
}

// Add attributes to page
export const initPage = ({ page, step }) => {
  const formData = initForm(page)

  // Skippable if there are no components with minSelections > 0
  const skippable = !page.layoutContent.components.find(
    (component) => component.minSelections && component.minSelections > 0
  )

  const valid =
    skippable ||
    validCheck({ components: page.layoutContent.components, formData })

  return {
    pages: {
      [`step_${step}`]: {
        page,
        formData,
        skippable,
        valid
      }
    },
    step
  }
}

// Prepare formData for submission
export const unloadForm = (page) => {
  const data = []

  page.page.layoutContent.components.forEach((component) => {
    const slug = component.slug
    const value = page.formData[slug] // t/f (bool), selected slug (single), or array of selected slugs (multi)

    switch (component.type) {
      case 'boolean-1':
        data.push({ slug, value })
        break
      case 'multi-select-1':
      case 'multi-select-2':
      case 'multi-select-3':
      case 'multi-select-4':
      case 'single-select-1':
        value &&
          data.push({
            slug,
            options: value.map((opt) => ({ slug: opt, value: true }))
          })
        break
    }
  })

  return data
}
