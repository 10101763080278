import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import { StripeCheckoutProvider } from 'context'

import TempEntry from '../../TempEntry'
const MembershipNew = React.lazy(() =>
  import(
    /* webpackChunkName: 'membership-new' */ 'components_v2/Membership/New'
  )
)

export const MembershipNewTempEntry = ({ stripeApiKey, user }) => {
  return (
    <TempEntry mt='0px' legacyPage user={user}>
      <StripeCheckoutProvider stripeApiKey={stripeApiKey}>
        <Suspense fallback=''>
          <MembershipNew />
        </Suspense>
      </StripeCheckoutProvider>
    </TempEntry>
  )
}

MembershipNewTempEntry.propTypes = {
  stripeApiKey: PropTypes.string.isRequired,
  user: PropTypes.object
}

export default MembershipNewTempEntry
