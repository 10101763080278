import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'ui/bend'
import { GlobalStyle } from 'ui/chandra'
import { useToggleFocus } from 'hooks'

import EntryProviders from './EntryProviders'
import Header from '../Header'
import Footer, { footerHeight } from '../Footer'
import IE, { isIE } from '../IE'
import { useAuthFeedback } from '../AuthProviders'

import ToastContainer from 'ui/bend/blocks/ToastContainer'

/****************************************
/* TODO: React Refactor
/*  This is the temporary entry point for redesigned pages.
/*  Once we switch over to full React, we can do away with this page.
****************************************/

// Display controls
const defaultControls = {
  alert: true,
  navbar: true,
  navbarLinks: true, // Links, hoverables, search, etc.
  navbarAuth: true,
  footer: true,
  footerLinks: true,
  footerFlush: {
    mobile: false,
    desktop: false
  }
}

export const TempEntry = ({
  loadUser,
  user,
  controls,
  deprecated,
  children,
  ...rest
}) => {
  const localControls = {
    ...defaultControls,
    ...controls
  }

  useAuthFeedback()
  useToggleFocus()
  return (
    <EntryProviders user={user} loadUser={loadUser}>
      <GlobalStyle />
      <Header controls={localControls} />
      <Box
        mt={['0px', null, '64px']}
        pb={
          localControls.footer
            ? footerHeight({
                pad: true,
                links: localControls.footerLinks,
                flush: localControls.footerFlush
              })
            : '0px'
        }
        {...rest}
      >
        {isIE ? <IE /> : { ...children }}
      </Box>
      {localControls.footer && (
        <Footer
          links={localControls.footerLinks}
          flush={localControls.footerFlush}
        />
      )}
      <ToastContainer />
    </EntryProviders>
  )
}

TempEntry.defaultProps = {
  // See defaultControls above
  controls: {}
}

TempEntry.propTypes = {
  loadUser: PropTypes.bool,
  user: PropTypes.object,
  controls: PropTypes.object,
  children: PropTypes.node
}

export default TempEntry
