// These are the Featured Coaches on the main page.
// Using a Const until we get an API point set up with an Admin interface for changing them.

export default [
  {
    name: 'Bianca Wise',
    alt: 'Bianca Wise in a straight-leg stretch',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Bianca.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Bianca.png'
    },
    tagline: 'Pilates Instructor',
    slug: 'bianca-melas'
  },
  {
    name: 'Annie Landa',
    alt: 'Annie Landa smiling outdoors',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Annie.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Annie.png'
    },
    tagline: 'Yoga Instructor',
    slug: 'annie-moves'
  },
  {
    name: 'Louis Chandler',
    alt: 'Louis Chandler seated outside',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Louis.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Louis.png'
    },
    tagline: 'Fitness Instructor',
    slug: 'louis-chandler'
  },
  {
    name: 'Katia Pryce',
    alt: 'Katia Pryce smiling and flipping her hair back',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Katia.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Katia.png'
    },
    tagline: 'Dance Instructor',
    slug: 'katia-pryce'
  },
  {
    name: 'Kirat Randhawa',
    alt: 'Kirat Randhawa smiling with her hands on her heart',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Kirat.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Kirat.png'
    },
    tagline: 'Meditation Instructor',
    slug: 'kirat'
  },
  {
    name: 'Nicole Berrie',
    alt: 'Nicole Berrie in a kitchen holding lemons and green juice',
    thumbnails: {
      desktop:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Nicole.png',
      mobile:
        'https://alomoves.s3.amazonaws.com/manual_uploads/shared/home/pc-june-2023/instructors/Instructor-Nicole.png'
    },
    tagline: 'Nutrition Instructor',
    slug: 'nicole-berrie'
  }
]
