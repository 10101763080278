import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../TempEntry'
import TermsTextCanada from 'ui/acro/blocks/Disclaimers/TermsTextCanada'
import TermsTextUS from 'ui/acro/blocks/Disclaimers/TermsTextUS'
import { Header } from 'ui/acro/blocks/Disclaimers/DisclaimerStyles'
import { HorizontalLogo } from 'images'
import styled from 'styled-components'

const StyledLink = styled('a')`
  display: block;
  width: 200px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 25px;
`

const TermsTempEntry = ({ country = 'us' }) => {
  return (
    <TempEntry controls={{ alert: false, navbar: false }}>
      <div className='privacy_statement'>
        <StyledLink href='/'>
          <HorizontalLogo />
        </StyledLink>
        <Header>Terms of Service</Header>
        {country === 'ca' ? <TermsTextCanada /> : <TermsTextUS />}
      </div>
    </TempEntry>
  )
}

TermsTempEntry.propTypes = {
  country: PropTypes.string
}

export default TermsTempEntry
